@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.toaster {
  display: block;
  --width: 202px !important;

  .toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 24px;
    width: 100%;
    height: auto;
    --y: translateY(-50%);

    &.error .icon {
      color: #ef2525;
    }

    &.error, &.success, &.info, &.warning {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(2.5px);
      max-width: 202px;
    }

    .icon {
      display: block;
      height: 72px;
      width: 72px;
      margin-bottom: 12px;

      --toast-icon-margin-start: 0px;
      --toast-icon-margin-end: 0px;

      svg[data-prefix="far"] {
        font-size: 72px;
        width: 100%;
      }

      :global(.sonner-loader) {
        width: 72px;
        height: 72px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      text-align: center;

      .title {
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }

  &:where([data-sonner-toaster][data-y-position='top']) {
    top: 50%;
  }
}